import React, { useState, useEffect, useCallback } from "react";

import clsx from "clsx";
import { navigate } from "gatsby";
import { FaArrowLeft } from "react-icons/fa";

import { TableSearchParams } from "~/components/Common/Table";
import Tabs from "~/components/Common/Tabs";
import { LaboratoryInformation, LaboratoryNurses, Services, Schedule, Packs } from "~/components/Laboratory";
import TubeLoader from "~/components/Loaders/TubeLoader";
import { labAPI } from "~/utils/api/v2";
import type { Laboratory, Receiver, PackAvailability } from "~/utils/interfaces/Laboratory";
import type { Nurse } from "~/utils/interfaces/Nurse";
import type { GetPaginatedResultsPayload } from "~/utils/interfaces/request";
import type { ItemWithLabItem } from "~/utils/interfaces/Services";

type PossibleTab = "Información" | "Horarios" | "Servicios" | "Packs" | "HTs";

const tabs: PossibleTab[] = ["Información", "Horarios", "Servicios", "Packs", "HTs"];

type LaboratoryPageProps = {
  id: string;
};

const LaboratoryPage = ({ id }: LaboratoryPageProps) => {
  const [laboratory, setLaboratory] = useState<Laboratory | null>(null);
  const [receivers, setReceivers] = useState<Receiver[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [labMeasuresLoading, setLabMeasuresLoading] = useState<boolean>(false);
  const [labPanelsLoading, setLabPanelsLoading] = useState<boolean>(false);
  const [loadingAvailablePacks, setLoadingAvailablePacks] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<PossibleTab>(tabs[0]);
  const [assignedNurses, setAssignedNurses] = useState<Nurse[]>([]);
  const [unAssignedNurses, setUnAssignedNurses] = useState<Nurse[]>([]);
  const [labMeasures, setLabMeasures] = useState<GetPaginatedResultsPayload<ItemWithLabItem> | null>(null);
  const [labPanels, setLabPanels] = useState<GetPaginatedResultsPayload<ItemWithLabItem> | null>(null);
  const [availablePacks, setAvailablePacks] = useState<GetPaginatedResultsPayload<PackAvailability> | null>(null);

  const fetchLab = useCallback(async () => {
    setLoading(true);
    const foundLab = await labAPI.get(id);
    if (foundLab) {
      setLaboratory(foundLab);
    }
    setLoading(false);
  }, [id]);

  const fetchLabReceivers = useCallback(async () => {
    setLoading(true);
    const foundReceivers = await labAPI.listReceivers(id);
    if (foundReceivers) {
      setReceivers(foundReceivers);
    }
    setLoading(false);
  }, [id]);

  const fetchLabNurses = useCallback(async () => {
    setLoading(true);
    const labNurses = await labAPI.getLabNurses(id);
    if (labNurses) {
      const { assigned, unassigned } = labNurses;
      setAssignedNurses(assigned);
      setUnAssignedNurses(unassigned);
    }
    setLoading(false);
  }, [id]);

  const fetchLabMeasures = useCallback(
    async ({ page = 1, pageSize = 30, searchBy = null }: TableSearchParams) => {
      setLabMeasuresLoading(true);
      const foundItems = await labAPI.getLabItems(id, "lab-measures", { page, pageSize, searchBy });
      if (foundItems) {
        setLabMeasures(foundItems);
      }
      setLabMeasuresLoading(false);
    },
    [id],
  );

  const fetchLabPanels = useCallback(
    async ({ page = 1, pageSize = 30, searchBy = null }: TableSearchParams) => {
      setLabPanelsLoading(true);
      const foundItems = await labAPI.getLabItems(id, "lab-panels", { page, pageSize, searchBy });
      if (foundItems) {
        setLabPanels(foundItems);
      }
      setLabPanelsLoading(false);
    },
    [id],
  );

  const fetchAvailablePacks = useCallback(
    async ({ page = 1, pageSize = 30, searchBy = null }: TableSearchParams) => {
      setLoadingAvailablePacks(true);
      const foundPacks = await labAPI.getPackAvailability(id, { page, pageSize, searchBy });
      if (foundPacks) {
        setAvailablePacks(foundPacks);
      }
      setLoadingAvailablePacks(false);
    },
    [id],
  );

  useEffect(() => {
    fetchLabMeasures({});
  }, [fetchLabMeasures]);

  useEffect(() => {
    fetchLabPanels({});
  }, [fetchLabPanels]);

  useEffect(() => {
    if (currentTab !== "HTs") return;

    fetchLabNurses();
  }, [fetchLabNurses, currentTab]);

  useEffect(() => {
    fetchLab();
  }, [fetchLab]);

  useEffect(() => {
    fetchLabReceivers();
  }, [fetchLabReceivers]);

  useEffect(() => {
    if (currentTab !== "Packs") return;

    fetchAvailablePacks({});
  }, [fetchAvailablePacks, currentTab]);

  return (
    <div
      className={clsx(
        "w-full",
        "px-5 py-4",
        "flex flex-col gap-y-5 bg-examedi-white-pure",
        "rounded-lg border border-examedi-gray-4",
      )}
    >
      <div
        className="w-fit flex items-center gap-x-2 cursor-pointer hover:text-examedi-blue-strong"
        onClick={async () => navigate("/laboratory")}
      >
        <FaArrowLeft />
        Volver
      </div>
      <h1 className="text-4xl font-light">{laboratory?.display_name}</h1>
      <Tabs
        elements={tabs}
        onSelectTab={(value: string) => setCurrentTab(value as PossibleTab)}
      >
        {loading && (
          <div className="w-full h-110 flex items-center justify-center">
            <TubeLoader />
          </div>
        )}
        {!loading && (
          <>
            {currentTab === "Información" && !!laboratory && (
              <LaboratoryInformation
                laboratory={laboratory}
                receivers={receivers}
                fetchLaboratoryCallback={fetchLab}
                fetchReceiversCallback={fetchLabReceivers}
              />
            )}
            {currentTab === "Horarios" && !!laboratory && (
              <Schedule
                laboratory={laboratory}
                country={laboratory.country}
                fetchLaboratoryCallback={fetchLab}
              />
            )}
            {currentTab === "Servicios" && !!laboratory && !!labMeasures && !!labPanels && (
              <Services
                laboratory={laboratory}
                labMeasures={labMeasures}
                labPanels={labPanels}
                labMeasuresLoading={labMeasuresLoading}
                labPanelsLoading={labPanelsLoading}
                fetchLabMeasuresCallback={fetchLabMeasures}
                fetchLabPanelsCallback={fetchLabPanels}
              />
            )}
            {currentTab === "Packs" && (
              <Packs
                availablePacks={availablePacks}
                fetchAvailablePacksCallback={fetchAvailablePacks}
                loading={loadingAvailablePacks}
              />
            )}
            {currentTab === "HTs" && !!laboratory && (
              <LaboratoryNurses
                laboratory={laboratory}
                receivers={receivers}
                assignedNurses={assignedNurses}
                unAssignedNurses={unAssignedNurses}
                fetchLaboratoryNursesCallback={fetchLabNurses}
              />
            )}
          </>
        )}
      </Tabs>
    </div>
  );
};

export default LaboratoryPage;
